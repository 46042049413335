import { defineStore } from 'pinia';

const sharedState = {
    profiles: []
}

export default defineStore('CompareStore', {
    state: () => sharedState,
    actions: {
        addProfile(profile) {
            if (this.profiles.length < 3 && !this.profiles.find(o=>o.id==profile.id)) {
                this.profiles.push(profile);
            }
        },
        removeProfile(profile) {
            const index = this.profiles.findIndex(o=>o.id===profile.id);
            if (index >= 0) this.profiles.splice(index, 1);
        },
        removeAllProfiles() {
            this.profiles = [];
        }
    }
})